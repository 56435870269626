import { useState, useEffect } from "react"
import {  memo } from "react"
import { getRazerPage } from "../../api/razer"

import ClipLoader from "react-spinners/ClipLoader";
const override = {
    display: "block",
    margin: "0 auto",
    // borderColor: "red",
};

const RazerScreen = ({userid, price, type}) => {
    const [errMsg, setErrMsg] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getRazerPage(type, userid, price, "prod").then(res => {
            window.location.replace(res.data.data)
            
        }).catch(err => {
            console.log(err)
            setErrMsg("An error occurred")
        }).finally(() => {
            setLoading(false)
        })
    }, [])
    return(
        <>
            <ClipLoader color="black" 
                loading={loading} size={50} 
                cssOverride={override}
                />
            {errMsg !== "" && 
                <>{errMsg}</>
            }
        </>
    )
}

export default memo(RazerScreen)