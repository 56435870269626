export const GCASH_LIST_ACCOUNT = "/api/gcash/list-account"
export const GCASH_VERIFY_TRANSACTION = "/api/gcash/verfiy-transaction/{userid}/{ref}"

export const PAYMAYA_LIST_ACCOUNT = "/api/maya/list-account"
export const PAYMAYA_VERIFY_TRANSACTION = "/api/maya/verfiy-transaction/{userid}/{ref}"



export const WALLET_LIST_ACCOUNT = "/api/wallet/list-account"
export const WALLET_VERIFY_TRANSACTION = "/api/wallet/verfiy-transaction/{userid}/{ref}"
// export const RAZER_ENDPOINT = "https://globalapi.gold.razer.com/payout/payments";
// export const RAZER_SANDBOX_ENDPOINT = "https://globalapi.gold-sandbox.razer.com/payout/payments";
