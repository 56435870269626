import { Button, Container, Image } from "react-bootstrap"
import styles from "./pay.module.scss"
import logo from "../../assets/maya/img/logo.png"
import guide1 from "../../assets/maya/img/1.png"
import guide2 from "../../assets/maya/img/2.png"
import guide3 from "../../assets/maya/img/3.png"
import { Trans, useTranslation } from "react-i18next"
import { useEffect } from "react"
import * as actions from "../../store/paymaya"
import { Redirect, useLocation } from "react-router-dom"
import { useState } from "react"

import { useForm } from 'react-hook-form'

import copy from "../../assets/common/img/ic-copy.png"

const MayaDirectScreen = () => {
    const { t } = useTranslation("translations")

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');
    const price = new URLSearchParams(search).get('price');
    const ref = new URLSearchParams(search).get('ref');
    const [accounts, setAccount] = useState([])
    const [successMsg, setSuccessMsg] = useState("")

    function copyToClipboard(e, text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert("Copied!")
    };

    useEffect(() => {
        actions.paymayaListAccount(userid, ref).then(res => {
            setAccount(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const {
        handleSubmit,
        register,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        setSuccessMsg("")
        actions.paymayaVerfifyTransaction(userid, data.ref).then(res => {
            setSuccessMsg("success")
        }).catch(err => {
            console.log(err)
            setError("ref", { type: 'focus', message: err?.response?.data?.msg }, { shouldFocus: true })
        })
    }

    return userid && price ? (
        <Container className={styles.wrapper} fluid>
            <div className={styles.header}>
                <Image src={logo} alt="Gcash" />
            </div>

            <div className={styles.content}>
                <div className="row">
                    <div className="col-sm-8 left py-2">
                        <div className="title">
                            <p>
                                <Trans

                                    i18nKey={'txt_gcash_1'}
                                    ns="translations"
                                    values={{ price: price, userid: userid }}
                                    components={{
                                        userid: <span className="hl" />,
                                        price: <span className="hl" />,
                                    }}
                                />
                            </p>
                            <p>{t("txt_gcash_2")}</p>
                        </div>

                        <Container className="accounts">
                            <div className="row">
                                {
                                    accounts.map((item, ind) => (
                                        <div key={item + ind} className="item col-md-6">
                                            <div className="number row">
                                                <div className="col-4 text-right">{t("txt_gcash_3")}:</div>
                                                <div className="col-8 val">
                                                    <div className="">{item.GCashId}</div>
                                                    <div className=""><Image className="copy" onClick={(e) => copyToClipboard(e, item.GCashId)} src={copy} alt={"copy"} /></div>
                                                </div>
                                            </div>
                                            <div className="name row"><span className="col-4 text-right">{t("txt_gcash_4")}:</span><span className="col-8">{item.GCashName}</span></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Container>

                        <Container className="mt-3">

                            <div className="row amount ">
                                <div className="col-lg-6 info">
                                    <div className="row">
                                        <div className="col-4">{t("txt_gcash_11")}</div>
                                        <div className="col-8"><Trans

                                            i18nKey={'txt_gcash_12'}
                                            ns="translations"
                                            values={{ price: `PHP ${price}`, userid: userid }}
                                            components={{
                                                userid: <span className="hl" />,
                                                price: <span className="hl" />,
                                            }}
                                        /></div>
                                    </div>
                                </div>

                            </div>

                        </Container>

                        <Container className="mt-3">
                            <div className="row msg ">
                                <div className="col-lg-6 info">
                                    <div className="row">
                                        <div className="col-4">{t("txt_gcash_13")}</div>
                                        <div className="col-8 val">
                                            <Trans

                                                i18nKey={'txt_gcash_14'}
                                                ns="translations"
                                                values={{ userid: `${userid}ID` }}
                                                components={{
                                                    userid: <span className="hl" />,
                                                    price: <span className="hl" />,
                                                }}
                                            />
                                            <div className=""><Image className="copy" onClick={(e) => copyToClipboard(e, `${userid}ID`)} src={copy} alt={"copy"} /></div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </Container>

                        <div className="notice mt-3">
                            <div className="tit">{t("txt_gcash_5")}</div>
                            <div className="item"><Trans

                                i18nKey={'txt_gcash_6'}
                                ns="translations"
                                values={{ userid: `${userid}ID` }}
                                components={{
                                    userid: <span className="hl" />,
                                }}
                            /></div>
                            <div className="item">{t("txt_gcash_7")}</div>
                            <div className="item">{t("txt_gcash_8")}</div>
                            <div className="item">{t("txt_gcash_9")}</div>
                            <div className="item">{t("txt_gcash_10")}</div>
                        </div>

                        <form action="#" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mt-3">
                                <input className="form-control text-center" type="text" name="ref" {...register("ref")} />
                                <small className="hl">{t(errors?.ref?.message)}</small>
                                <small className="text-success">{successMsg}</small>
                            </div>
                            <div className="form-group mt-3 text-center">
                                <Button type="submit" ></Button>
                            </div>
                        </form>
                    </div>
                    <div className="col-sm-4 guide text-center">
                        <Image src={guide1} alt="Paymaya Guide" />
                        <Image src={guide2} alt="Paymaya Guide" />
                        <Image src={guide3} alt="Paymaya Guide" />
                    </div>
                </div>
            </div>
        </Container>
    ) : (<Redirect to={"/404"} />)
}

export default MayaDirectScreen