import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import ph from "./translations/ph.json";
import id from "./translations/id.json";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: en
        },
        ph: {
            translations: ph
        },
        id: {
            translations: id
        }
    },
    fallbackLng: ["id"],
    debug: false,
    whitelist: ["en", "id"],
    // lng: "th",
    

    // have a common namespace used around the full app
    // ns: ["translations"],
    // defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        // formatSeparator: ","
    },

    react: {
        wait: true,
        useSuspense : true
    }
});

export default i18n;