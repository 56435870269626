import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import RazerScreen from "./prod"
import RazerSandboxScreen from "./sandbox"
import { useMemo, memo } from "react"

const Razer = () => {
    let { path, url } = useRouteMatch();

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');
    const price = new URLSearchParams(search).get('price');
    const type = new URLSearchParams(search).get('type');

    const isValidParams = useMemo(() => {

        if (userid === null){
            return false;
        }

        if (price === null){
            return false;
        }

        return true
    }, [userid, price, type])
    return (
        <Switch>
            <Route  path={`${path}/sandbox`}>
                {!isValidParams ? <Redirect to={"/404"} /> : <RazerSandboxScreen userid={userid} price={price} type={type} />}
                
            </Route>

            <Route exact path={path}>
                {!isValidParams ? <Redirect to={"/404"} /> : <RazerScreen userid={userid} price={price} type={type} />}
            </Route>

            <Route  path={`${path}/return`}>
                <>Success</>
            </Route>
            

            
            
        </Switch>
    )
}

export default memo(Razer)

