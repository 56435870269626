import { Button, Container, Image } from "react-bootstrap"
import styles from "./pay.module.scss"
import logo from "../../assets/gcash/new/gcash.png"
import guide from "../../assets/gcash/new/anh.png"
import { Trans, useTranslation } from "react-i18next"
import { useEffect } from "react"
import * as actions from "../../store/gcash"
import { Redirect, useLocation } from "react-router-dom"
import { useState } from "react"

import { useForm } from 'react-hook-form'

import copy from "../../assets/gcash/new/copy.png"
import star from "../../assets/gcash/new/star.png"

const GcashDirectScreen = () => {
    const { t } = useTranslation("translations")

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');
    const price = new URLSearchParams(search).get('price');
    const ref = new URLSearchParams(search).get('ref');
    const [accounts, setAccount] = useState([])
    const [successMsg, setSuccessMsg] = useState("")

    function copyToClipboard(e, text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert("Copied!")
    };

    useEffect(() => {
        actions.gcashListAccount(userid, ref).then(res => {
            setAccount(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const {
        handleSubmit,
        register,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        setSuccessMsg("")
        actions.gcashVerfifyTransaction(userid, data.ref).then(res => {
            setSuccessMsg("success")
        }).catch(err => {
            console.log(err)
            setError("ref", { type: 'focus', message: err?.response?.data?.msg }, { shouldFocus: true })
        })
    }

    return userid && price ? (
        <Container className={styles.wrapper} fluid>
            <div className={styles.header}>
                <Image src={logo} alt="Gcash" />
            </div>

            <div className={styles.content}>
                <div className="row">
                    <div className="col-xs-7 col-sm-7">
                        <div className="title text-center hl">
                            <p>
                                <Trans

                                    i18nKey={'txt_gcash_n1'}
                                    ns="translations"
                                    values={{ price: price, userid: userid }}
                                    components={{
                                        hl: <span className="hl" />,
                                    }}
                                />
                            </p>

                        </div>

                        <Container className="accounts">
                            <div className="mb-2">
                                <Trans

                                    i18nKey={'txt_gcash_n2'}
                                    ns="translations"
                                    values={{ price: price, userid: userid }}
                                    components={{
                                        b: <b />,
                                    }}
                                />
                            </div>
                            <div className="row">
                                {
                                    accounts.map((item, ind) => (
                                        <div key={item + ind} className="col-6 p-2">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <div className="number">{item.GCashId}</div>
                                                    <div className="name">{item.GCashName}</div>
                                                </div>
                                                <div>
                                                    <img className="copy" onClick={(e) => copyToClipboard(e, item.GCashId)} src={copy} alt="copy" />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Container>

                        <Container className="mt-3 amount">
                            <div className="row py-2">
                                <div className="col-4">
                                    <b>Amount</b>
                                </div>
                                <div className="col-8">
                                    <span className="hl">PHP {price}</span>
                                </div>
                            </div>

                        </Container>

                        <Container className="form">
                            <div className="mt-3 mb-2">
                                <b>Step 2:</b>
                                Maaari mong i-paste ang Ref.no mula sa mensahe sa kahon sa ibaba o ilagay ang huling 5 na numero ng Ref.no upang matanggap ang chip</div>

                            <form method="post" action="#" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="ref" {...register("ref")} />
                                    <small className="hl">{t(errors?.ref?.message)}</small>
                                    <small className="text-success">{successMsg}</small>
                                </div>
                                <div className="form-group d-flex justify-content-center mb-3 mt-3">
                                    <button type="submit"></button>
                                </div>
                            </form>

                        </Container>

                        <Container className="notice">
                            <div className="d-flex align-items-center"><span>* Ang ref.no ay isang pagkakasunod-sunod ng mga numero tulad ng larawan sa tabi nito</span><img className="mx-1" src={star} alt="" /></div>
                            <h5>
                                <b>Pay attention!</b>
                            </h5>
                            <div>1- Paki-check ang gcash number na kasalukuyang naka-display sa shop bago mag-transfer ng pera para matanggap agad ang chip</div>
                            <div>2- Para makuha ang pinaka mabilis na chips, I-enter ang Ref.no  pagtapos ng loading. Pag hindi mo in-enter ang Ref.no, pagtapos ng 1 oras makakatanghap ka ng refund (para sa bagong player) o ang chips ay matetransfer sa game id na ang iyong ginamit nung bumili ka gamit ang iyong gcash recently (para sa player na bumili ng chips dati).</div>
                            <div>3- Hindi namin bubuksan ang Clip, ito ay mag eexpire pagkatapos ng 3 araw at maibabalik ito sa Gcash account ninyo.</div>
                            <div>4- Huwag magpadala ng mga mensahe ng transaksyon o Ref.no sa sinuman</div>
                            <div>5- Pag natapos mo na ang lahat ng steps at hindi mo pa din natanggap ang iyong chips, pakicontact kame para masuportahan ka</div>
                            <div className="row justify-content-center mt-2">
                                <a className="btn mt-2" href="https://www.facebook.com/tongits.online"></a>
                            </div>
                        </Container>
                    </div>
                    <div className="col-xs-5 col-sm-5 guide">
                        <Image src={guide} alt="Gcash Guide" />
                    </div>
                </div>
            </div>
        </Container>
    ) : (<Redirect to={"/404"} />)
}

export default GcashDirectScreen