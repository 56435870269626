import * as api from "../api/wallet"

export const walletListAccount = (userid, type) => {

    return new Promise((resolve, reject) => {
        api.getWalletListAccount(userid, type)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}

export const walletVerfifyTransaction = (userid, ref) => {

    return new Promise((resolve, reject) => {
        api.verfifyWalletTransaction(userid, ref)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}