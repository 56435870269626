import axios from "axios";

export const getRazerPage = (type, userid, price, env) => {
    userid = parseInt(userid)
    type = parseInt(type)
    price = parseInt(price)

    let url = "/api/razer/get-razer-payment";
    let config = {
        method: 'POST',
        url: `${url}`,
        data: {type, userid, price, env},
        headers: {
            "Content-Type": "application/json",
        }
    };

    return axios(config);
}