import * as api from "../api/gcash"
export const gcashListAccount = (vip, ref) => {

    return new Promise((resolve, reject) => {
        api.getListAccount(vip, ref)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}

export const gcashVerfifyTransaction = (userid, ref) => {

    return new Promise((resolve, reject) => {
        api.verfifyGcashTransaction(userid, ref)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}