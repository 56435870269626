import axios from "axios";
import { WALLET_LIST_ACCOUNT, WALLET_VERIFY_TRANSACTION } from "./endpoint";

export const getWalletListAccount = (userid , type) => {
    const searchParams = new URLSearchParams();
    searchParams.append("userid", userid);
    searchParams.append("type", type);

    let url = `${WALLET_LIST_ACCOUNT}?${searchParams.toString()}`;
    let config = {
        method: 'GET',
        url: `${url}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios(config);
}

export const verfifyWalletTransaction = (userid, ref) => {
    let url = WALLET_VERIFY_TRANSACTION.replace("{userid}", userid).replace("{ref}", ref)
    let config = {
        method: 'POST',
        url: `${url}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios(config);
}