import { Button, Container, Image } from "react-bootstrap"
import styles from "./pay.module.scss"
import logo from "../../assets/dana/img/logo.png"
import guide1 from "../../assets/dana/img/image_dana.png"
import { Trans, useTranslation } from "react-i18next"
import { useEffect } from "react"
import * as actions from "../../store/wallet"
import { Redirect, useLocation } from "react-router-dom"
import { useState } from "react"

import { useForm } from 'react-hook-form'

import copy from "../../assets/dana/img/icon_copy.png"

const DanaDirectScreen = () => {
    const { t } = useTranslation("translations")

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');
    const price = new URLSearchParams(search).get('price');
    const ref = new URLSearchParams(search).get('ref');
    const [accounts, setAccount] = useState([])
    const [items_of_last_row, setItemOfLastRow] = useState([])
    const [successMsg, setSuccessMsg] = useState("")

    function copyToClipboard(e, text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert("Copied!")
    };

    useEffect(() => {
        actions.walletListAccount(userid, 0).then(res => {
            setAccount(res.data)


        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (accounts.length > 0) {
            if (accounts.length % 2 == 1) {
                setItemOfLastRow([accounts.length - 1])
            } else {
                setItemOfLastRow([accounts.length - 1, accounts.length - 2])
            }
        }
    }, [accounts])

    const {
        handleSubmit,
        register,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        setSuccessMsg("")
        actions.walletVerfifyTransaction(userid, data.ref).then(res => {
            setSuccessMsg("Berhasil! Silakan buka \"Chip gratis\" untuk menerima chip")
        }).catch(err => {
            console.log(err)
            setError("ref", { type: 'focus', message: err?.response?.data?.msg }, { shouldFocus: true })
        })
    }

    return userid && price ? (
        <Container className={styles.wrapper} fluid>
            <div className={styles.header}>
                <Image src={logo} alt="Dana" />
            </div>

            <div className={styles.content}>
                <div className="row">
                    <div className="col-sm-8 left py-2">
                        <div className="title text-center mb-3">
                            <div>Beli <span className="hl">{price}</span> Rp untuk ID <span className="hl">{userid}</span></div>
                            <div>Silahkan transfer uang ke akun DANA kami</div>
                        </div>

                        <div className="accounts d-flex w-100 justify-content-between">
                            {

                                accounts.map((item, ind) => (
                                    <div key={item + ind} className={`item d-flex justify-content-between ${items_of_last_row.length === 1 ? "last-odd-item" : "" } ${items_of_last_row.includes(ind) ? "last-row" : ""}`}>
                                        <div className="">
                                            <div>Akun: {item.GCashId}</div>
                                            <div>Nama: {item.GCashName}</div>
                                        </div>
                                        <div>
                                            <Image className="copy" onClick={(e) => copyToClipboard(e, item.GCashId)} src={copy} alt={"copy"} />
                                        </div>
                                    </div>
                                ))

                            }
                        </div>

                        <div className="mt-3">

                            <div className="amount ">
                                <div className="info">
                                    <div className="col-4">Jumlah: Rp <span className="hl">{price}</span></div>
                                </div>

                            </div>

                        </div>

                        <div className="mt-3 info d-flex justify-content-between">

                            <div className="">dengan pesan transfer (harus diisi): <span className="hl">ID{userid}</span></div>
                            <div className=""><Image className="copy" onClick={(e) => copyToClipboard(e, `ID${userid}`)} src={copy} alt={"copy"} /></div>

                        </div>

                        <div className="mt-3">
                            Setelah transfer berhasil, harap menunggu 1-2 menit dan periksa "Chip Gratis" untuk mendapatkan chip.
                        </div>
                        <div className="hl">
                            Jika kamu transfer tapi lupa melampirkan catatan ID game, silahkan masukkan ID transaksi di bawah ini untuk mendapatkan chip
                        </div>



                        <form action="#" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mt-3">
                                <input className="form-control text-center" type="text" name="ref" {...register("ref")} />
                                <small className="hl">{t(errors?.ref?.message)}</small>
                                <small className="text-success">{successMsg}</small>
                            </div>
                            <div className="form-group mt-3 text-center">
                                <Button type="submit" ></Button>
                            </div>
                        </form>

                        <div className="notice mt-3">
                            <div className="tit">Pemberitahuan</div>
                            <div className="item">- Masukkan ID game yang benar untuk mendapatkan chip: <span className="hl">ID{userid}</span></div>
                            <div className="item">- Jangan memberikan invoice transfer kepada orang lain karena mereka dapat memasukkan ID transaksi untuk mendapatkan chip</div>
                            <div className="item">Kami tidak bertanggung jawab jika kamu transfer ke akun DANA yang bukan milik kami</div>
                            <div className="item">Jika kamu transfer uang tanpa memberikan ID game, dan setelah 30 menit tidak memasukkan ID transaksi, dana akan dikembalikan</div>
                            <div className="item">- Jika chip tidak diterima, silahkan kontak admin untuk meminta bantuan</div>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                            <a className="support d-block" href="https://www.messenger.com/t/376510312204272"></a>
                        </div>


                    </div>
                    <div className="col-sm-4 guide text-center">
                        <Image src={guide1} alt="Paymaya Guide" />
                    </div>


                </div>


            </div>
        </Container>
    ) : (<Redirect to={"/404"} />)
}

export default DanaDirectScreen