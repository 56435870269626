import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MayaDirectScreen from './pay';


const MayaDirect = () => {
    return (
        <Switch>
            <Route path={"/"} component={MayaDirectScreen} />
        </Switch>
    )
}

export default MayaDirect