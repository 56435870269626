import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeScreen from './page/home';
import NotFound from './page/404';
import GcashDirect from './page/gcash-direct';
import MayaDirect from './page/maya-direct';
import Razer from "./page/razer";
import DanaDirect from './page/dana-direct';

import OvoDirect from "./page/ovo-direct"



const App = () => {
	return (
		<Router>
			<Switch>
				<Route exact path={"/"} component={HomeScreen} />
				<Route exact path={"/gcash-direct"} component={GcashDirect} />
				<Route exact path={"/paymaya-direct"} component={MayaDirect} />
				<Route exact path={"/dana-direct"} component={DanaDirect} />
				<Route exact path={"/ovo-direct"} component={OvoDirect} />
				<Route  path="/razer">
					<Razer />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</Router>
	)

}

export default App