import axios from "axios";
import { PAYMAYA_LIST_ACCOUNT, PAYMAYA_VERIFY_TRANSACTION } from "./endpoint";

export const getPaymayaListAccount = (vip, ref) => {
    const searchParams = new URLSearchParams();
    searchParams.append("vip", vip);
    searchParams.append("ref", ref);

    let url = `${PAYMAYA_LIST_ACCOUNT}?${searchParams.toString()}`;
    let config = {
        method: 'GET',
        url: `${url}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios(config);
}

export const verfifyPaymayaTransaction = (userid, ref) => {
    let url = PAYMAYA_VERIFY_TRANSACTION.replace("{userid}", userid).replace("{ref}", ref)
    let config = {
        method: 'POST',
        url: `${url}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios(config);
}