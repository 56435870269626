import * as api from "../api/paymaya"
export const paymayaListAccount = (vip, ref) => {

    return new Promise((resolve, reject) => {
        api.getPaymayaListAccount(vip, ref)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}

export const paymayaVerfifyTransaction = (userid, ref) => {

    return new Promise((resolve, reject) => {
        api.verfifyPaymayaTransaction(userid, ref)
            .then(({ data }) => {
                return resolve(data)
            }).catch((err) => {
                reject(err) 
            })
    })

}