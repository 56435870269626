import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GcashDirectScreen from './pay';


const GcashDirect = () => {
    return (
        <Switch>
            <Route path={"/"} component={GcashDirectScreen} />
        </Switch>
    )
}

export default GcashDirect